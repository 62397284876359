import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";
import Rimp from "rimp";
import { isTablet, isMobile } from "react-device-detect";

import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.mainFooter}>
      {!isMobile || isTablet ? (
        <div className={classes.container}>
          <div className="ipadHidden">
            <GridContainer
              className={classes.paddingContainer + " " + classes.customGrid}
              justify="center"
            >
              <GridItem sm={6} xs={12} md={4} className="webFooter">
                <h5 className="linkForStore">Visit our online store</h5>
                <a href="http://bit.ly/FUNNTEE" target="_blank">
                  <Button
                    variant="contained"
                    size="large"
                    className="visitStoreButton"
                    // fullWidth
                  >
                    Click here to visit
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </Button>
                </a>
              </GridItem>
              <GridItem sm={6} xs={12} md={6} className="webFooter">
                <h5 className="newsLetter">Subscribe For NewsLetter</h5>
                <Rimp
                  buttonValue={`Submit`}
                  buttonStyles={`btn-subscribe`}
                  containerStyles={`newsletter__form`}
                  completeMessage={`Thanks, we'll send you an email to confirm!`}
                  placeholder={"Enter your email address"}
                  mailChimpUrl="//url.us10.list-manage.com/subscribe/post?u=user&amp;id=list"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      ) : (
        <></>
      )}

      <hr className={classes.footerDivider} />
      <div className={classes.container}>
        <GridContainer justify="space-around">
          <GridItem sm={6} xs={12} md={4} className={classes.gridFooter}>
            <p style={{ margin: 0 }}>Copyright 2020, All rights reserved</p>
          </GridItem>
          <GridItem sm={6} xs={12} md={4} className={classes.gridFooter}>
            <div className={classes.footerBottom}>
              <p> managed with ❤ by </p>
              <a href="https://nceptio.com/" target="_Blank">
                {/* <img
                  src="/nceptio_logo2.png"
                  style={{ marginLeft: 10, width: 85 }}
                ></img> */}
                <p className={classes.nceptioText}> Nceptio </p>
              </a>
            </div>
          </GridItem>
          <GridItem sm={6} xs={12} md={4} className={classes.gridFooter}>
            <div className="socialMedia">
              <a
                href="https://business.facebook.com/pg/Funntee-103757154521520/"
                target="_blank"
              >
                {" "}
                <i className="fab fa-facebook" />{" "}
              </a>
              <a href="https://www.instagram.com/funntee_com/">
                <i className="fab fa-instagram" aria-hidden="true"></i>
              </a>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
