import React from "react";
import { Button } from "@material-ui/core";
import { isTablet, isMobileOnly } from "react-device-detect";
import Rimp from "rimp";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks";
import Footer from "components/Footer/Footer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import "./Presentation.scss";

export default function PresentationPage() {
  return (
    <div>
      <Header links={<HeaderLinks dropdownHoverColor="info" />} fixed />
      <div>
        <video autoPlay loop muted playsInline className="videoHomepage">
          <source src={"FUNNTEE-homepage-video.mp4"} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {isMobileOnly || isTablet ? (
          <div className="mobileContent">
            <GridContainer
              className="paddingContainer customGrid"
              justify="center"
            >
              <GridItem
                sm={12}
                xs={12}
                md={12}
                className="webFooter mobileDivContent"
              >
                <h5 className="linkForStore">Visit our online store</h5>
                <a href="http://bit.ly/FUNNTEE" target="_blank">
                  <Button
                    variant="contained"
                    size="large"
                    className="visitStoreButton"
                  >
                    Click here to visit
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </Button>
                </a>
              </GridItem>
              <GridItem sm={12} xs={12} md={12} className="webFooter">
                <h5 className="newsLetter">Subscribe For NewsLetter</h5>
                <Rimp
                  buttonValue={`Submit`}
                  buttonStyles={`btn-subscribe`}
                  containerStyles={`newsletter__form`}
                  completeMessage={`Thanks, we'll send you an email to confirm!`}
                  placeholder={"Enter your email address"}
                  mailChimpUrl="//url.us10.list-manage.com/subscribe/post?u=user&amp;id=list"
                />
              </GridItem>
            </GridContainer>
          </div>
        ) : (
          <> </>
        )}
      </div>
      <Footer />
    </div>
  );
}
