/*!

=========================================================
* Material Kit PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";
// pages for this product
// import LoginPage from "views/LoginPage/LoginPage.js";
import PresentationPage from "views/PresentationPage/PresentationPage.js";


var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/* <Route path="/rooms" component={Rooms} />
      <Route path="/about-us" component={AboutUs} />
      <Route path="/amenities" component={Amenities} />
      <Route path="/events" component={Events} />
      <Route path="/book-stay" component={BookStay} />
      <Route path="/policies" component={Policies} />
      <Route path="/event/photo-shoots" component={PhotoShoot} />
      <Route path="/event/weddings" component={Weddings} />
      <Route path="/wedding-inquiry" component={WeddingInquiry} />
      <Route path="/photoshoot-inquiry" component={PhotoShootInquiry} />
      <Route path="/city-culture" component={CityCulture} /> */}
      <Route path="/" component={PresentationPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
