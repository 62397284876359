/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import styles from "../../assets/jss/material-kit-pro-react/components/headerLinksStyle";
import { ListItemText } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const classes = useStyles();
  return (
    <div>
      <div className={classes.topFooter + " " + classes.headerLink}>
        <h6 className={classes.headerTopLink}>
          <EmailIcon style={{ fontSize: 14, marginRight: 10}}/> 
          <a href="mailto: info@funntee.com" style={{ color: 'inherit' }} > info@funntee.com </a>
        </h6>
        <h6 className={classes.contact + " " + classes.headerTopLink}>
          <PhoneIcon style={{ fontSize: 14, marginRight: 10}} />
          <a href="tel:+1 (469) 111-1166" style={{ color: 'inherit' }}> +1 (469) 111-1166</a>
          <EmailIcon style={{ fontSize: 14, marginRight: 10 }} />
          <a href="mailto: info@funntee.com" style={{ color: "inherit" }}>
            {" "}
            info@funntee.com{" "}
          </a>
        </h6>
      </div>
      {/* <List
        className={
          classes.list + " " + classes.mlAuto + " " + classes.headerLink
        }
      >
        <ListItem className={classes.listItem}>
          <Link to="/" className={classes.dropdownLink}>
            <ListItemText className={classes.headerListText}>
              {" "}
              All Products{" "}
            </ListItemText>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/rooms" className={classes.dropdownLink}>
            <ListItemText className={classes.headerListText} > Rooms </ListItemText>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/" className={classes.dropdownLink}>
            <ListItemText className={classes.headerListText}>
              {" "}
              Apparel{" "}
            </ListItemText>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/" className={classes.dropdownLink}>
            <ListItemText className={classes.headerListText}>
              {" "}
              Homeware{" "}
            </ListItemText>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/" className={classes.dropdownLink}>
            <ListItemText className={classes.headerListText}>
              {" "}
              Accessories{" "}
            </ListItemText>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/" className={classes.dropdownLink}>
            <ListItemText className={classes.headerListText}>
              {" "}
              About{" "}
            </ListItemText>
          </Link>
        </ListItem>
      </List> */}
    </div>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "black"
  ])
};
